import React from 'react'

const CallToAction = (props) => {
    return (
        <div className="text-center w-10/12 mx-auto pb-6">
            <div className="divider bg-darkblue mb-4"></div>
    <h3 className="mb-4 lg:mb-0 lg:text-4xl lg:leading-tight">{props.ctaCopy}</h3> 
            <a className="h3 lg:text-4xl lg:leading-tight mr-2 sm:mr-6 text-darkblue" href="tel:01424575144">01424 575144</a> <a className="h3 lg:text-4xl lg:leading-tight ml-2 sm:ml-6 text-darkblue" href="mailto:office@kaspc.co.uk">office@kaspc.co.uk</a>
        </div>    
    );
}

export default CallToAction