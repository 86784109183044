import React from 'react'

const Images = props => {
  return (
    <div className="w-full mx-auto md:flex justify-center">
        <div className="w-full md:w-6/12 md:mr-2">
            <img src={props.imageOneSrc.source_url} className="md:h-64 object-cover rounded-lg mb-4" />
        </div>

        <div className="w-full md:w-6/12 md:ml-2">
            <img src={props.imageTwoSrc.source_url} className="md:h-64 object-cover rounded-lg mb-4" />
        </div>
    </div>
  )
}

export default Images
