import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Layout from '../components/Layout'
import CallToAction from '../components/blocks/CallToAction'
import Seo from '../components/Seo'
import Images from '../components/blocks/Images'
//import BannerLinks from '../components/blocks/BannerLinks'

const Care = () => {
  const data = useStaticQuery(graphql`
    {
      allWordpressPage(limit: 1, filter: { wordpress_id: { eq: 11 } }) {
        edges {
          node {
            title
            status
            content
            wordpress_id
            featured_media {
              source_url
            }
            acf {
              call_to_action_content
              image_one {
                source_url
              }
              image_two {
                source_url
              }
            }
          }
        }
      }
    }
  `)
  const acfData = data.allWordpressPage.edges[0].node.acf;
  return (
    <Layout featuredImg={
      data.allWordpressPage.edges[0].node.featured_media.source_url
    }
    pageName={data.allWordpressPage.edges[0].node.title}>
      <section className="py-4 w-full lg:w-8/12 mx-auto">
        <div
          dangerouslySetInnerHTML={{
            __html: data.allWordpressPage.edges[0].node.content,
          }}
        ></div>
      </section>
      <section className="py-4 w-11/12 lg:w-8/12 mx-auto">
        <Images imageOneSrc={acfData.image_one} imageTwoSrc={acfData.image_two}/>
      </section>
      {/* <section className="p-4 w-full"> */}
        {/* <BannerLinks linkOne="chemicals" linkOneText="Chemical Prices" linkTwo="services" linkTwoText="Service Prices" /> */}
        {/* <Link to="chemicals" className="mb-4 lg:mb-0 sm:mr-6 button button-lg">Chemical Prices</Link>
        <Link to="services" className="sm:ml-6  button button-lg">Service Prices</Link> */}
      {/* </section> */}
      <CallToAction ctaCopy={acfData.call_to_action_content} />
      <Seo title={data.allWordpressPage.edges[0].node.title} description={data.allWordpressPage.edges[0].node.content} image={data.allWordpressPage.edges[0].node.featured_media.source_url} article={true} />
    </Layout>
  )
}

export default Care